import { FC, useCallback, useMemo } from 'react';
import useAccount from '../Tools/Hooks/useAccount';
import useLocalStorage from '../Tools/Hooks/useLocalStorage';
import { arrayInterSec, arrayEqual } from '../Tools/Utils/Array';
import { LocationType } from '../Tools/Store/reducers/LocalStorageReducer';

const UserAccess: FC<UserAccessPropType> = ({ superUser, modules, locations, children }) => {
	const { user } = useAccount();

	let accessible = true;

	const isSuperUser = user?.access?.isSuper || false;
	const userModules = user?.access?.web_modules || [];
	const userLocations = user?.access?.locations || [];

	if (modules) {
		accessible = accessible && (arrayInterSec(userModules, modules || []).length > 0 || userModules?.includes?.('all'));
	}

	if (locations) {
		accessible =
			accessible && (arrayInterSec(userLocations, locations || []).length > 0 || userLocations?.includes?.('all'));
	}

	if (superUser) {
		accessible = accessible && isSuperUser === superUser;
	}

	if (isSuperUser) accessible = true;

	return <>{accessible ? children : null}</>;
};

export const useUserAccess = () => {
	const { locations } = useLocalStorage();
	const { user: originalUser } = useAccount();

	const modules = ['culinary', 'maintenance', 'payroll'] as Exclude<ModuleAccess, 'all'>[];

	// TODO implement access for developer

	const user = useMemo(() => ({ ...originalUser }), []);

	const isSuperUser = !!user?.access?.isSuper;

	const hasAccess = useCallback((...accesses: AccessType[]) => {
		if (isSuperUser || accesses?.includes('super')) return isSuperUser;

		return accesses.every(access => {
			if (access === 'all-locations') {
				const uLocs = user?.access?.locations || [];
				if (uLocs.includes('all')) return true;
				return arrayEqual(
					uLocs,
					locations?.map(l => l.slug)
				);
			}

			if (access === 'all-modules') {
				const uMods = user?.access?.web_modules || [];
				if (uMods.includes('all')) return true;
				return arrayEqual(uMods, modules);
			}

			const has = [...(user?.access?.locations || []), ...(user?.access?.web_modules || [])]?.includes(access);

			return has;
		});
	}, []);

	const hasSomeAccess = useCallback((...accesses: AccessType[]) => accesses?.some(access => hasAccess(access)), []);

	// const hasSomeAccess = useCallback((...accesses: AccessType[]) => accesses?.some(), []);

	let accessibleLocations: LocationType[] = [];
	accessibleLocations = locations.filter(ll => isSuperUser || user?.access?.locations?.includes(ll?.slug || ''));

	let accessibleModules: Exclude<ModuleAccess, 'all'>[] = [];
	accessibleModules = user?.access?.web_modules as Exclude<ModuleAccess, 'all'>[];
	if (isSuperUser || user?.access?.web_modules?.includes('all')) accessibleModules = modules;

	return {
		hasAccess,
		hasSomeAccess,
		accessibleModules,
		accessibleLocations,
	};
};

type ModuleAccess =
	| 'giftcard'
	| 'r&m'
	| 'r&m/read'
	| 'r&m/write'
	| 'payroll'
	| 'payroll/reports'
	| 'payroll/employees'
	| 'payroll/hr-reports'
	| 'payroll/payroll-history'
	| 'payroll/payrate-changes'
	| 'payroll/process-daily-payroll'
	| 'maintenance'
	| 'culinary'
	| 'sales'
	| 'score-cards'
	| 'new-hires'
	| 'forms'
	| 'daily-process-payroll'
	| 'all';

type LocationAccess = 'sandy-springs' | 'avalon' | 'washington-dc' | 'colony-square' | 'all';

export type AccessType = Exclude<ModuleAccess | LocationAccess | 'super' | 'all-locations' | 'all-modules' | 'dev', 'all'>;

type UserAccessPropType = {
	superUser?: boolean;
	modules?: ModuleAccess[];
	locations?: LocationAccess[];
};

export default UserAccess;
