import crypto from 'crypto';

export const capitalize = (str: string) => (!!str ? `${str.charAt(0).toUpperCase()}${str.slice(1)}` : '');

export const lowercaseFirst = (str: string): string => `${str.charAt(0).toLowerCase()}${str.slice(1)}`;

export const isEmail = (str: string) =>
	/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
		str
	);

export const uppercaseWords = (str: string): string =>
	str
		?.toLowerCase()
		?.split(/-|_/)
		?.map((i: string) => capitalize(i))
		?.join(' ');

export const stringChunkRight = (str: string, size = 3) => {
	const length = str.length;
	const chunks = Array(Math.ceil(length / size));
	if (length) {
		chunks[0] = str.slice(0, length % size || size);
		for (let i = 1, index = chunks[0].length; index < length; i++) {
			chunks[i] = str.slice(index, (index += size));
		}
	}
	return chunks;
};

export const numberSplitter = (num: number | string, decimal: number = 2) => {
	if (typeof num === 'string') num = parseFloat(num) || 0;
	num = num?.toFixed(decimal);
	let decimalPart = num?.split('.')?.[1] || '00';
	let intPart = num?.split('.')?.[0] || num || '0';
	let intPartChunks = stringChunkRight(intPart);
	return intPartChunks.join(',') + '.' + decimalPart;
};

export const camelCase = (str: string) => str.trim().replace(/[-_\s]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ''));

export const slugify = (str: string): string =>
	str
		.toLowerCase()
		.replace(/\s+/g, '-')
		.replace(/[^\w-]+/g, '');

export const isContain = (string: string, searchValue: string) => {
	const lowerStr = string?.toLocaleLowerCase?.()?.trim();
	const lowerSearchValue = searchValue?.toLocaleLowerCase?.()?.trim();
	return lowerStr?.includes(lowerSearchValue);
};

export const userPaginationId = (user: any) => user?.userId?.slice(0, 6);

export const employeePaginationId = (employee: any) => employee?.employeeId?.slice(0, 6);

export const jwtDecode = (token: string): { exp?: number; iat?: number } => {
	if (token === '') return {};
	return JSON.parse(
		decodeURIComponent(
			atob(token.split('.')[1].replace('-', '+').replace('_', '/'))
				.split('')
				.map(c => `%${('00' + c.charCodeAt(0).toString(16)).slice(-2)}`)
				.join('')
		)
	);
};

export const generateRandomString = (length: number) => {
	const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
	const numbers = '0123456789';
	return Array(length)
		.fill('')
		.map(_ => {
			const set = Math.random() < 0.5 ? chars : numbers;
			return set[~~(Math.random() * set.length)];
		})
		.join('');
};

export const awsIdGenerator = () => [8, 4, 4, 4, 12].map(length => generateRandomString(length)).join('-');

export const uid = () => generateRandomString(~~Math.random() * 10 + 3);

export const createHashId = (str: string) => crypto.createHash('sha256').update(str).digest('base64');

export const getEmployeeFullName = (o: { lastName?: string; middleName?: string; firstName?: string }) =>
	[o?.lastName, o?.middleName, o?.firstName]?.filter(x => !!x)?.join(' ');

export const urlHashes = (hash?: string) => hash?.split('#')?.slice(1) || [];

export const quoteExtractor = (str: string) => {
	const quote = /'([^']+)/.exec(str)?.[1];
	const other = str.replace(`'${quote}'`, '');
	return { quote, other };
};

export const emailRegex =
	/^((([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,}))?$/i;

export const getClassName = (name: string): string => name?.toLowerCase()?.split(' ')?.join('-');

export const extractLatLng = (link: string) => {
	if (!isContain(link, '@') && !isContain(link, 'query=')) return undefined;
	const v = isContain(link, '@') ? link?.split('@') : link?.split('query=');
	const latLng = v?.[1].split(',');
	const lat = +latLng?.[0];
	const lng = +latLng?.[1];

	return { lat, lng };
};

export const extractPlace = (link: string) => {
	if (!isContain(link, 'q=') && !isContain(link, 'place/')) return undefined;
	const v = isContain(link, 'q=') ? link?.split('q=') : link?.split('place/');
	const res = v?.[1]?.split('/');
	return res?.[0];
};

export const userNameAbbreviation = (str: string) => {
	if (!str) return '';
	const parts = str?.split(' ');
	if (parts.length === 1) return str.slice(0, 2)?.toUpperCase();
	else {
		const first = parts[0].slice(0, 1);
		const last = parts[parts.length - 1].slice(0, 1);
		return `${first}${last}`;
	}
};

export const extractZoom = (link: string) => {
	if (!isContain(link, 'z')) return undefined;
	// const regexp = new RegExp(/+?dz/);
	// const zoom = link.match(regexp);

	return 14;
};

export const ticketNoOrganize = (str: string) => +str?.replace('TK', '');

export const splitString = (str: string) => {
	let parts = str?.split?.(/(\d+)/);
	let letters = parts?.[0];
	let numbers = +parts?.[1];
	return `${letters} #${numbers}`;
};
